//@flow

import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import reactStringReplace from "react-string-replace";
import { sha256 } from "js-sha256";
import Componentify from "react-componentify";

import apiMethod from "../../api/apiMethod";
import { __ } from "../../lib/translate";
import {
  hrefSearch,
  rcookie,
  loadScript,
  getGlobalLocationId,
  isInternetExplorer
} from "../../lib/utils";
import { planNames, plansQuota, testPeriods } from "../../lib/plansInfo";
import { ColorType, PlanIDType } from "../../types/dataTypes.components";
import { signToCurrency } from "@pcloud/web-utilities/dist/payments/constants.payments";
import { sendAnalyticsEvent } from "../../lib/sendAnalyticsEvent";

import PaymentTabs from "./PaymentTabs";
import { Loader } from "./PaymentLoader";
import CreditCardBox from "./CreditCardBox";
import PaypalBox from "./PaypalBox";
import CreditCardFormTemplate from "./CreditCardFormTemplate";
import PrefilledCreditCardForm from "./PrefilledCreditCardForm";
import {
  sendCJPageVisitTag,
  sendCJConversionTag,
  CJ_PLANS_PREFIX
} from "@pcloud/web-utilities/dist/lib/cjAffiliate";
import { PROVIDERS, PROVIDERS_IDS, SUBSC_PERIOD } from "@pcloud/web-utilities/dist/config/constants";
import { linkConverter } from "../../lib/componentifyConverters";
import { getPromoParam } from "@pcloud/web-utilities/dist/lib/ptr";

type PaymentViewProps = {
  isBusiness?: boolean,
  planId: string,
  billingPeriod: "month" | "year" | "lifetime" | "onetime",
  providerId: number,
  color: ColorType,
  price: number,
  currency: "$" | "€" | "₤",
  country: string,
  cart: Array<PlanIDType>,
  paymentProvider: string,
  setPaymentProvider: () => void,
  setCart: () => void,
  showLoading: () => void,
  showSuccessMessage: () => void,
  open3DsecureIframe: () => void,
  on3DsecureError: () => void,
  analytics: {
    category: string,
    label: string
  },
  discount?: string,
  endTrial?: boolean,
  prorationtime?: boolean,
  members?: number,
  title?: string,
  showTabs?: boolean
};

const PaymentView = ({
  isBusiness = false,
  planId,
  billingPeriod,
  providerId,
  color,
  price,
  currency = "$",
  country = "US",
  discount,
  cart,
  paymentProvider,
  setPaymentProvider,
  setCart,
  showLoading,
  showSuccessMessage,
  open3DsecureIframe,
  on3DsecureError,
  analytics,
  endTrial = false,
  members = 0,
  prorationtime = 0,
  title = "",
  showTabs = true
}: PaymentViewProps) => {
  const [view, setView] = useState("card");
  const DEFAULT_TABS = [
    {
      tabId: "card",
      tabTitle: __("Credit Card", "Credit Card"),
      clickAction: setView
    },
    {
      tabId: "paypal",
      tabTitle: "Paypal",
      clickAction: setView
    }
  ];

  const userInfo = useSelector(({ pCloudUser }) => pCloudUser.userinfo);
  const auth = useSelector(({ pCloudUser }) => pCloudUser.token);
  const [tabs, setTabs] = useState(DEFAULT_TABS);
  const [cardProvider, setCardProvider] = useState("safecharge");
  const [loading, setLoading] = useState(true);
  const [userPaymentSource, setUserPaymentSource] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [cjData, setCjData] = useState(null);
  const billingInfo = useSelector(({ business }) => business.billingInfo);
  const { billing = {}, payer = {}, subscriptions = [] } = billingInfo;
  const { provider: billingProviderId, period: billingPeriodBusiness, businessplanid } = billing;
  const isIE = isInternetExplorer();

  const promoParams = {
    label: getPromoParam("label"),
    channelid: getPromoParam("channelid")
  };

  useEffect(() => {
    setErrorMessage("");
  }, [view]);

  useEffect(() => {
    if (hrefSearch("ErrCode") === "0") {
      afterPaymentActions(hrefSearch("transactionid"));
      return;
    }

    if (isBusiness && billingProviderId === PROVIDERS_IDS.PAYPAL) {
      setBusinessViewPayPal();
    } else {
      loadPaymentData();
    }

    setCart(planId);
    loadSubscribedProducts();
    loadScript("https://js.stripe.com/v2/", () => {});
    loadScript("https://js.stripe.com/v3/", () => {});
    loadScript("https://js.braintreegateway.com/web/3.76.0/js/client.min.js", () => {});
    loadScript("https://js.braintreegateway.com/web/3.76.0/js/paypal-checkout.min.js", () => {});
    loadScript("https://js.braintreegateway.com/web/3.76.0/js/data-collector.min.js", () => {});
    initCJData();
  }, []);

  useEffect(() => {
    if (cjData) {
      sendCJPageVisitTag(cjData);
    }
  }, [cjData]);

  const initCJData = () => {
    const newCjData = {};
    const items = [];
    const locationid = getGlobalLocationId() || "0";
    const sha256Email = sha256 ? sha256(userInfo.email) : "";
    let totalDiscount = 0;
    const periodBusiness = SUBSC_PERIOD.YEARLY === billingPeriod ? "Y" : "M";
    const fistLetterPeriod = isBusiness ? periodBusiness : billingPeriod[0]?.toUpperCase();
    const cjPlanId = CJ_PLANS_PREFIX[planId] + "_" + fistLetterPeriod;

    newCjData.emailHash = sha256Email;
    newCjData.userId = locationid + "_" + userInfo.userid;

    items.push({
      itemId: cjPlanId,
      quantity: 1,
      unitPrice: price,
      discount: 0
    });

    newCjData.cartSubtotal = price;
    newCjData.currency = signToCurrency[currency] || "USD";
    newCjData.items = items;

    setCjData(newCjData);
  };

  const getPaymentSource = () => {
    return new Promise((resolve, reject) => {
      apiMethod(
        "listpaymentsources",
        { auth },
        (res: any) => {
          resolve(res);
        },
        {
          errorCallback: () => {
            setLoading(false);
            reject();
          }
        }
      );
    });
  };

  const getPaymentProvider = () => {
    return new Promise((resolve, reject) => {
      const params = { auth };

      if (rcookie("forcecard")) {
        params.card = rcookie("forcecard");
      }

      if (rcookie("forcecountry")) {
        params.country = rcookie("forcecountry");
      }

      if (isIE) {
        params.browser = "IE";
      }

      apiMethod(
        "listpaymentmethods",
        params,
        (res: any) => {
          resolve(res);
        },
        {
          errorCallback: () => {
            setLoading(false);
            reject();
          }
        }
      );
    });
  };

  const setActiveSubsView = (subscription = {}) => {
    const { billingprovider } = subscription;
    let newView = "card";
    let newProvider = "safecharge";
    let newCardProvider = "safecharge";

    if (
      billingprovider === PROVIDERS_IDS.SAFECHARGE ||
      billingprovider === PROVIDERS_IDS.STRIPE
    ) {
      newView = "card";
      newProvider = PROVIDERS[billingProviderId].toLowerCase();
      newCardProvider = PROVIDERS[billingProviderId].toLowerCase();
    } else if (billingprovider === PROVIDERS_IDS.PAYPAL) {
      newView = "paypal";
      newProvider = "paypal";
    }

    const isSubscription = billingPeriod === "month" || billingPeriod === "year";
    const isBusinessSubs = billingPeriod === SUBSC_PERIOD.MONTHLY || billingPeriod === SUBSC_PERIOD.YEARLY;

    if ((isSubscription || isBusinessSubs) && tabs.length) {
      let newTabs = tabs.map(tab => (tab.tabId !== newView ? { ...tab, disabled: true } : tab));
      setTabs(newTabs);
    }

    setTimeout(() => {
      setLoading(false);
    }, 300);

    setPaymentProvider(newProvider);
    setCardProvider(newCardProvider);
    setView(newView);
  };

  const setBusinessViewPayPal = () => {
    let newView = "card";
    let newProvider = "safecharge";
    let newCardProvider = "safecharge";

    if (
      billingProviderId === PROVIDERS_IDS.SAFECHARGE ||
      billingProviderId === PROVIDERS_IDS.STRIPE
    ) {
      newView = "card";
      newProvider = PROVIDERS[billingProviderId].toLowerCase();
      newCardProvider = PROVIDERS[billingProviderId].toLowerCase();
      setUserPaymentSource(payer);
    } else if (billingProviderId === PROVIDERS_IDS.PAYPAL) {
      newView = "paypal";
      newProvider = "paypal";
      setUserPaymentSource(payer);
    }

    if ((
      billingPeriodBusiness === SUBSC_PERIOD.MONTHLY ||
      billingPeriodBusiness === SUBSC_PERIOD.YEARLY
    ) && tabs.length) {
      let newTabs = tabs.map(tab => (tab.tabId !== newView ? { ...tab, disabled: true } : tab));
      setTabs(newTabs);
    }

    setTimeout(() => {
      setLoading(false);
    }, 300);

    setPaymentProvider(newProvider);
    setCardProvider(newCardProvider);
    setView(newView);
  };

  const loadPaymentData = () => {
    const paymentSourcePromise = getPaymentSource();
    const paymentProviderPromise = getPaymentProvider();

    Promise.all([paymentSourcePromise, paymentProviderPromise]).then(values => {
      const activeSubscription = subscriptions.filter(subsc => subsc.status === 1);
      const paymentSourceData = values[0];
      const paymentProviderData = values[1];
      let newView = "card";
      let newProvider = "safecharge";
      let newCardProvider = "safecharge";
      let hasProvider = false;

      if (paymentProviderData.paymentmethods.length) {
        const paymentMethod = paymentProviderData.paymentmethods[0];
        newView = paymentMethod.type;
        newProvider = paymentMethod.method;
        newCardProvider = paymentMethod.method;
      }

      if (
        paymentSourceData.stripe ||
        paymentSourceData.safecharge ||
        paymentSourceData.paypal ||
        paymentSourceData.braintree
      ) {
        hasProvider = true;

        if (paymentSourceData.stripe) {
          newView = "card";
          setUserPaymentSource(paymentSourceData);
          newProvider = "stripe";
          newCardProvider = "stripe";
        } else if (paymentSourceData.safecharge && (!isIE || (isIE && billingPeriod !== "lifetime" && billingPeriod !== "onetime"))) {
          setUserPaymentSource(paymentSourceData);
          newView = "card";
          newProvider = "safecharge";
          newCardProvider = "safecharge";
        } else if (paymentSourceData.braintree) {
          setUserPaymentSource(paymentSourceData);
          newView = "paypal";
          newProvider = "braintree";
        } else if (paymentSourceData.paypal) {
          setUserPaymentSource(paymentSourceData);
          newView = "paypal";
          newProvider = "paypal";
        }
      } else if (activeSubscription.length) {
        const subscription = activeSubscription[0];
        const { billingprovider } = subscription;

        if (
          billingprovider === PROVIDERS_IDS.SAFECHARGE ||
          billingprovider === PROVIDERS_IDS.STRIPE
        ) {
          newView = "card";
          newProvider = PROVIDERS[billingProviderId].toLowerCase();
          newCardProvider = PROVIDERS[billingProviderId].toLowerCase();
          hasProvider = true;
        } else if (billingprovider === PROVIDERS_IDS.PAYPAL) {
          newView = "paypal";
          newProvider = "paypal";
          hasProvider = true;
        } else if (billingprovider === PROVIDERS_IDS.BRAINTREE) {
          newView = "paypal";
          newProvider = "braintree";
          hasProvider = true;
        }
      }

      const isSubscription = billingPeriod === "month" || billingPeriod === "year";
      const isBusinessSubs = billingPeriod === SUBSC_PERIOD.MONTHLY || billingPeriod === SUBSC_PERIOD.YEARLY;

      if (tabs.length && hasProvider && (isSubscription || isBusinessSubs)) {
        let newTabs = tabs.map(tab => (tab.tabId !== newView ? { ...tab, disabled: true } : tab));
        setTabs(newTabs);
      }

      setTimeout(() => {
        setLoading(false);
      }, 300);

      setPaymentProvider(newProvider);
      setCardProvider(newCardProvider);
      setView(newView);
    });
  };

  const loadSubscribedProducts = () => {
    const { lastsubscription } = userInfo;

    if (lastsubscription && lastsubscription.status != "active") {
      return;
    }

    let choosenProducts = planId;
    choosenProducts = choosenProducts.map(productId => Number(productId));
    const otherProducts =
      lastsubscription &&
      lastsubscription.products.filter(subscribedProduct => {
        const choosenPremiumProducts = choosenProducts.filter(i => [1, 3, 14, 15].indexOf(i) != -1);
        const userChoosedPremiumProduct = !!choosenPremiumProducts.length;
        const userHasPremiumSubscription = [1, 3, 14, 15].indexOf(subscribedProduct) != -1;

        const skipPremium = !(userChoosedPremiumProduct && userHasPremiumSubscription);

        return choosenProducts.indexOf(planId) == -1 && skipPremium;
      });

    setCart(
      otherProducts && billingPeriod !== "lifetime" && billingPeriod !== "onetime"
        ? choosenProducts.concat(otherProducts)
        : choosenProducts
    );
  };

  const afterPaymentActions = useCallback(res => {
    const transactionID = res.transactionId || res.transactionid || res.id;
    const { label, category } = analytics;
    const analyticsParams = {
      category: "promo",
      action: "purchase",
      label: promoParams.label || label,
      os: 4
    };

    const analyticsData = {
      revenue: price,
      currency: signToCurrency[currency] || "USD"
    };

    if (promoParams.channelid) {
      analyticsData.channelid = promoParams.channelid;
    }

    sendAnalyticsEvent(analyticsParams, analyticsData);

    if (gaAll && typeof gaAll === "function") {
      const itemsInfo = [
        {
          name: cart.map(planid => planNames[planid]).join(" + "),
          price: price,
          quantity: 1
        }
      ];

      // gaAll("event", "purchase", {
      //   transaction_id: transactionID,
      //   value: price,
      //   currency: signToCurrency[currency] || "USD",
      //   items: itemsInfo,
      //   shipping: "0",
      //   tax: "0"
      // });

      gaAll("event", "conversion", {
        'send_to': 'AW-946159937/3Uu5CKv9wo0YEMGClcMD',
        transaction_id: transactionID,
        value: price,
        currency: signToCurrency[currency] || "USD",
        items: itemsInfo,
        shipping: "0",
        tax: "0"
      });
    }


    if (typeof ga4 !== "undefined") {
      const ga4Items = cart.map(planID => {
        return {
          item_name: planNames[planID] + " " + testPeriods[billingPeriod],
          item_brand: "pCloud",
          item_category: testPeriods[billingPeriod],
          item_variant: plansQuota[planID] || "",
          affiliation: rcookie("ref"),
          coupon: discount || "",
          price: price,
          currency: signToCurrency[currency] || "USD",
          quantity: 1
        }
      });

      ga4({
        eventname: "purchase",
        ecommerce: {
          currency: signToCurrency[currency] || "USD",
          value: price,
          transaction_id: transactionID,
          payment_type: view === "card" ? cardProvider : view,
          items: ga4Items
        }
      })
    }

    if (fbq && typeof fbq == "function") {
      fbq("track", "Purchase", {
        value: price,
        currency: signToCurrency[currency] || "USD"
      });
    }

    const cjDataModified = { ...cjData };
    cjDataModified.orderId = transactionID;
    delete cjDataModified.cartSubtotal;
    sendCJConversionTag(cjDataModified);

    if (sessionStorage.pCloud) {
      sessionStorage.removeItem("pCloud");
    }

    const newProvider = view === "card" ? cardProvider : "paypal";
    setPaymentProvider(newProvider);
    showSuccessMessage();
  });

  const onPaymentProcessing = () => {
    const newTabs = tabs.map(tab => {
      return { disabled: true, ...tab };
    });
    setTabs(newTabs);
  };

  const afterPaymentProcessing = () => {
    if (!userPaymentSource || billingPeriod === "onetime") {
      setTabs(DEFAULT_TABS);
    }
  };

  const renderView = () => {
    let paymentSource = null;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader color={color} />
        </LoaderWrapper>
      );
    } else if (view === "card") {
      if (userPaymentSource && userPaymentSource.stripe) {
        paymentSource = userPaymentSource.stripe.sources[0];
      } else if (userPaymentSource && userPaymentSource.safecharge && !isIE) {
        paymentSource = userPaymentSource.safecharge.sources[0];
      }

      return (
        <CreditCardBox
          auth={auth}
          planId={cart}
          period={billingPeriod}
          discount={discount}
          provider={cardProvider}
          billingAddress={{
            email: userInfo.email,
            country: country?.toUpperCase()
          }}
          RenderForm={CreditCardFormTemplate}
          RenderPrefilledForm={PrefilledCreditCardForm}
          userPaymentSource={paymentSource}
          afterPaymentActions={afterPaymentActions}
          showLoading={showLoading}
          open3DsecureIframe={open3DsecureIframe}
          on3DsecureError={on3DsecureError}
          color={color}
          price={price}
          currency={currency}
          onPaymentProcessing={onPaymentProcessing}
          afterPaymentProcessing={afterPaymentProcessing}
          endTrial={endTrial}
          businessplanid={businessplanid}
          members={members}
          prorationtime={prorationtime}
          isBusiness={isBusiness}
          isIE={isIE}
        />
      );
    } else if (view === "paypal") {
      if (userPaymentSource && userPaymentSource.braintree) {
        paymentSource = userPaymentSource.braintree.sources[0];
      } else if (userPaymentSource && userPaymentSource.email) {
        // Business paypal
        paymentSource = userPaymentSource;
      }

      return (
        <PaypalBox
          auth={auth}
          planId={cart}
          period={billingPeriod}
          providerId={providerId}
          discount={discount}
          afterPaymentActions={afterPaymentActions}
          showLoading={showLoading}
          userPaymentSource={paymentSource}
          color={color}
          price={price}
          currency={currency}
          members={members}
          businessplanid={businessplanid}
          endTrial={endTrial}
          onPaymentProcessing={onPaymentProcessing}
          afterPaymentProcessing={afterPaymentProcessing}
          isBusiness={isBusiness}
          paypalButtonLabel="pay"
        />
      );
    }
  };

  return (
    <Wrapper className="paymentview_wrapper">
      <PaypalContainer id="paypal-container" />
      <div
        className="payment_wrap safecharge_wrap"
        data-payment-box="safecharge"
        style={{ display: "none" }}
      >
        <div className="inner">
          <div className="vertical_align">
            <div className="spinner" />
            <div className="sf_logo_wrap">
              <div className="sf_logo_wrap_inner" />
            </div>
          </div>
        </div>
      </div>
      <PaymentMethodTitle className="payment-method-title">
        {title ? title : __("select_payment_method", "Select payment method")}
      </PaymentMethodTitle>
      {!isIE ? (
        <AlignContainer className="payment-align-container">
          <FormWrapper className="payment-form-wrapper">
            {(!loading && showTabs) ? <PaymentTabs color={color} tabs={tabs} activeTab={view} /> : null}
            {renderView()}
          </FormWrapper>
        </AlignContainer>
        ) : <Message><span>{__("payments_not_supported_ie")}</span></Message>
      }
      <DisclaimerWrapper>
        {billingPeriod !== "lifetime" && billingPeriod !== "onetime" ? (
          <div className="auto_renewal">
            <Componentify
              text={__(isBusiness ? "business_autorenew_policy_message_componentify" : "autorenew_policy_message_componentify")}
              converters={[linkConverter]}
            />
          </div>
        ) : null}

        {view === "card" ? (
          <div className="stored_credentials">
            {reactStringReplace(__("stored_credentials_message"), /(%here%)/g, (match, i) => (
              <span
                className="inline-button"
                onClick={() => {
                  window.open("https://www.pcloud.com/terms_and_conditions.html", "_blank");
                }}
              >
                {__("stored_credentials_here_parameter")}
              </span>
            ))}
          </div>
        ) : null}

        {view === "card" && paymentProvider === "safecharge" ? (
          <div className="safecharge_disclaimer">
            <div>
              {__("checkout_safecharge_terms_1")}
              {__("checkout_safecharge_terms_2")}
            </div>
            <a
              href="https://www.nuvei.com/legal/terms-of-use"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>{" "}
            |
            <a
              href="https://www.nuvei.com/legal/privacy-notice"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        ) : null}
        <div className="address">pCloud International AG | 74 Zugerstrasse Str, 6340 Baar, Switzerland</div>
      </DisclaimerWrapper>
    </Wrapper>
  );
};

export default PaymentView;

const Wrapper = styled.div`
  border-radius: inherit;
  @media (max-width: 768px) {
    padding: 20px;
    background-color: #ffffff;
  }
`;

const AlignContainer = styled.div`
  border-radius: 8.6px;
  box-shadow: 2.1px 2.1px 5px 0 rgba(0, 0, 0, 0.01);
  background-color: #ffffff;
  min-height: 430px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 26px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0px;
    min-height: 0px;
    border-radius: 0px;
  }
`;

const LoaderWrapper = styled.div`
  position: relative;
  min-height: 343px;
`;

const FormWrapper = styled.div`
  max-width: 440px;
  width: 100%;
  margin: 0 auto;

  .responsive-wrap {
    width: 100%;
  }
`;

const DisclaimerWrapper = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
  background-color: #fafafa;
  border-top: 1px solid #f3f3f3;
  padding: 20px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  a {
    text-decoration: none;
    color: #999999;
    font-weight: bold;
  }

  .address {
    font-weight: bold;
  }

  &>div {
    margin: 10px auto;
    &:first-of-type {
      margin-top: 0px;
    }
    &:last-of-type {
      margin-bottom: 0px;
    }
  }

  .stored_credentials {
    .inline-button {
      font-weight: bold;
      cursor: pointer;
    }
  }
`;

const LoaderText = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 19px;
  font-weight: 500;
  margin-top: 35px;
`;

const LoaderContainer = styled.div`
  min-height: 410px;
  border-radius: 15px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 640px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  border-radius: 15px;
  box-shadow: 0px 10px 20px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  padding: 40px;
  box-sizing: border-box;
`;

const PaymentMethodTitle = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 19px;
  font-weight: 500;
  padding-top: 40px;
`;

const PaypalContainer = styled.div`
  display: none;
`;

const Message = styled.div`
  min-height: 298px;
  height: 297px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0 30px;
`;
